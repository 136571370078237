import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import domtoimage from 'dom-to-image';
import randomstring from 'randomstring';

const Create = (props) => {
	let history = useHistory();

	useEffect(() => {
		if (!props.img) {
			history.push('/');
		}
	}, []);

	const [ caption, setCaption ] = useState('Enter your caption here..');
	const [ watermark, setWatermark ] = useState('');

	const handleCaptionChange = (e) => {
		setCaption(e.target.value);
	};

	const handleWatermarkChange = (e) => {
		setWatermark(e.target.value);
	};

	const handleBackClick = () => {
		history.push('/');
	};

	const handleSave = () => {
		let scale = 2;
		let elm = document.getElementById('meme-container');
		domtoimage
			.toPng(elm, {
				cachebust: true,
				height: elm.offsetHeight * scale,
				style: {
					transform: `scale(${scale}) translate(${elm.offsetWidth / 2 / scale}px, ${elm.offsetHeight /
						2 /
						scale}px)`
				},
				width: elm.offsetWidth * scale
			})
			.then(function(dataUrl) {
				let a = document.createElement('a');
				a.href = dataUrl;
				a.download = `EasyMeme-${randomstring.generate(5)}.png`;
				a.click();
			})
			.catch(function(error) {
				console.error('oops, something went wrong!', error);
			});
	};

	return (
		<div>
			<div className="title-head">
				<h1>EasyMeme</h1>
				<h3>Create modern memes easily and efficiently!</h3>

				<div className="main-container">
					<div className="meme-container">
						<div id="meme-container" style={{ backgroundColor: '#fff', overflow: 'hidden' }}>
							<p style={{ color: '#fff', display: !caption ? 'block' : 'none', visibility: 'hidden' }}>
								Caption Placehoder
							</p>
							<p
								id="meme-cap-location"
								style={{ backgroundColor: '#fff' }}
								dangerouslySetInnerHTML={{ __html: caption.replace(/\r\n|\r|\n/g, '<br />') }}
							/>
							<div id="img-container" className="img-container">
								<img id="meme-img" alt="meme pic" src={props.img} />
								<p id="meme-wat-location">{watermark}</p>
							</div>
						</div>
					</div>

					<div className="line-divider" />

					<div className="settings-container">
						<textarea
							id="meme-cap-txt"
							value={caption}
							onChange={handleCaptionChange}
							spellCheck="false"
							className="meme-caption-text"
						/>
						<input
							placeholder="Watermark (optional)"
							value={watermark}
							onChange={handleWatermarkChange}
							id="meme-wat-txt"
							maxLength="15"
							spellCheck="false"
						/>
						<button className="blue-btn" onClick={handleSave} style={{ marginRight: '5px' }}>
							Save Meme
						</button>
						<button className="green-btn" onClick={handleBackClick} style={{ marginLeft: '5px' }}>
							Back
						</button>

						{/* <input type="file" id="imgselect" multiple name="img" required /> */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Create;
