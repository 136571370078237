import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import Create from './components/Create';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-161908978-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
	const [ uploadedImg, setUploadedImg ] = useState('');

	return (
		<div>
			<Router>
				<Switch>
					<Route path="/" exact render={() => <Home setImg={setUploadedImg} />} />
					<Route path="/create" exact render={() => <Create img={uploadedImg} />} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
