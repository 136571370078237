import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TemplateBox from './TemplateBox';
import axios from 'axios';

const Home = (props) => {
	let history = useHistory();

	const [ showingErr, setShowingErr ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ searchTerm, setSearchTerm ] = useState('');
	const [ delayOneDone, setDelayOneDone ] = useState(false);
	const [ popArr, setPopArr ] = useState([]);

	const [ popArrToDisplay, setPopArrToDisplay ] = useState([]);

	function shuffle(array) {
		var currentIndex = array.length,
			temporaryValue,
			randomIndex;

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			// And swap it with the current element.
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = temporaryValue;
		}

		return array;
	}

	useEffect(() => {
		let callApi = async () => {
			let result = await axios.get('https://api.meme.kaamidev.com/get-templates');
			shuffle(result.data);
			setPopArr(result.data);
			setPopArrToDisplay(
				result.data
					.map((temp) => {
						return <TemplateBox key={temp.img} name={temp.name} setImg={props.setImg} img={temp.img} />;
					})
					.slice(0, 6)
			);
		};

		callApi();
	}, []);

	const handleSearchBox = (e) => {
		if (!e.target.value) {
			setPopArrToDisplay(
				popArr
					.map((temp) => {
						return <TemplateBox key={temp.img} name={temp.name} setImg={props.setImg} img={temp.img} />;
					})
					.slice(0, 6)
			);
		} else {
			setPopArrToDisplay(
				popArr
					.filter((entry) =>
						Object.values(entry).some(
							(val) =>
								typeof val === 'string' &&
								val.toLowerCase().replace("'", '').includes(searchTerm.toLowerCase().replace("'", ''))
						)
					)
					.map((temp) => {
						return <TemplateBox key={temp.img} name={temp.name} setImg={props.setImg} img={temp.img} />;
					})
					.slice(0, 6)
			);
		}
	};

	const handleChange = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();
		if (file.type === 'image/jpeg' || file.type === 'image/png') {
			reader.readAsDataURL(file);
			reader.addEventListener(
				'load',
				function() {
					// convert image file to base64 string
					props.setImg(reader.result);
					setIsLoading(true);
					setTimeout(() => {
						history.push('/create');
					}, 1000);
				},
				false
			);
		} else {
			setShowingErr(true);
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setDelayOneDone(true);
		}, 500);
	}, []);

	return (
		<div className="title-head">
			<h1>EasyMeme</h1>
			<h3>Create modern memes easily and efficiently!</h3>

			<div
				className="home-upload-box"
				style={{
					animation: showingErr ? 'shake 0.82s cubic-bezier(.36, .07, .19, .97) both' : ''
				}}
			>
				<p>Start by uploading a meme template.</p>
				<p style={{ color: 'red', display: showingErr ? 'block' : 'none', fontSize: 12 }}>
					Only PNGs and JPGs Currently Supported.
				</p>
				<button
					onClick={() => {
						document.getElementById('imginput').click();
					}}
					className="blue-btn"
					disabled={isLoading}
				>
					{!isLoading ? 'Browse Image' : 'Loading..'}
				</button>
				<input
					type="file"
					accept="image/png, image/jpeg"
					onChange={handleChange}
					id="imginput"
					style={{ display: 'none' }}
				/>
			</div>
			<div style={{ display: delayOneDone ? '' : 'none' }} className="home-popular-box">
				<p>
					<strong style={{ textDecoration: '', fontSize: '20px' }}>Featured Templates</strong>
				</p>
				<input
					type="text"
					onChange={(e) => {
						setSearchTerm(e.target.value);
						handleSearchBox(e);
					}}
					id="search-box-input"
					placeholder="Search All Templates"
				/>
				<button type="button" className="blue-btn">
					Search
				</button>
				<div className="templates-container">
					{popArrToDisplay}

					<p style={{ display: popArrToDisplay.length ? 'none' : 'block' }}>No templates found.</p>
				</div>
			</div>
		</div>
	);
};

export default Home;
