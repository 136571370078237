import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const TemplateBox = (props) => {
	const [ isZoomed, setIsZoomed ] = useState(false);

	let history = useHistory();

	const handleSelect = () => {
		props.setImg(props.img);
		history.push('/create');
	};

	const handleView = () => {
		setIsZoomed(true);
	};

	const handleZoomChange = useCallback((shouldZoom) => {
		setIsZoomed(shouldZoom);
	});

	return (
		<div className="template-container" style={{ display: 'inline-block' }}>
			<ControlledZoom
				zoomMargin={400}
				wrapStyle={{ pointerEvents: 'none' }}
				isZoomed={isZoomed}
				onZoomChange={handleZoomChange}
			>
				<img alt="meme" style={{ width: '300px' }} src={props.img} />
			</ControlledZoom>
			<p title={props.name}>
				{props.name.substring(0, 27)}
				{props.name.length > 27 ? '...' : ''}
			</p>
			<button onClick={handleSelect} className="blue-btn" style={{ marginRight: '2.5px' }}>
				Use Template
			</button>
			<button onClick={handleView} className="green-btn" style={{ marginLeft: '2.5px' }}>
				View
			</button>
		</div>
	);
};

export default TemplateBox;
